import React from 'react';
import get from 'lodash/get';
import { Placeholder, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { detailPageImagesSizes } from '../../constants';
import { IProps } from './Interfaces/Component.props';
import CommonImage from '../CommonImage';
import DetailStickyTopContext from '../../DetailStickyTopContext';
import { getHeightsInRem } from '../../helpers/utils';
import { ScreenClassRender, Visible, Hidden } from 'react-grid-system';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './Detail.scss';

class Detail extends React.Component<IProps, {}> {
  public render() {
    const commonImage = get(this.props, 'fields.image.value.src');
    const bynderImage = get(this.props, 'fields.bynderImage');
    return (
      <div>
        <div className="detail__body">
          <div className="detail__description">
            {get(this.props, 'fields.subHeadline') && (
              <Text tag="h3" field={get(this.props, 'fields.subHeadline', {})} />
            )}

            {get(this.props, 'fields.subSubHeadline') && (
              <Text tag="b" field={get(this.props, 'fields.subSubHeadline', {})} className="sub-sub-title" />
            )}

            <RichText field={get(this.props, 'fields.paragraph', {})} />

            <Hidden xs={true} sm={true}>
              <Placeholder name="deck" rendering={this.props.rendering} />
            </Hidden>
          </div>

          <div className="detail__images">
            {(() => {
              if (commonImage && bynderImage.value) {
                return (
                  <div className="detail__image">
                    <img src={bynderImage.value} />
                  </div>
                );
              } else if (!commonImage && bynderImage.value) {
                return (
                  <div className="detail__image">
                    <img src={bynderImage.value} />
                  </div>
                );
              } else if (commonImage) {
                return (
                  <DetailStickyTopContext.Consumer>
                    {context => (
                      <ScreenClassRender
                        render={(screenClass: string) => (
                          <div
                            className="detail__image"
                            style={{
                              top: `${getHeightsInRem(context, screenClass)}`,
                            }}
                          >
                            <CommonImage
                              field={get(this.props, 'fields.image', {})}
                              isNotLazy={true}
                              sizes={detailPageImagesSizes}
                            />
                          </div>
                        )}
                      />
                    )}
                  </DetailStickyTopContext.Consumer>
                );
              }
            })()}
          </div>

          <Visible xs={true} sm={true}>
            <Placeholder name="deck" rendering={this.props.rendering} />
          </Visible>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(Detail);
